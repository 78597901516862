import React from 'react'
import TeamPhotoWithLabels from './teamPhotoWithLabels'
import styles from '../people-grid.module.css'
import { Image } from '../Image'

export default function GridPlugin ({ columns, inColumns, type, text, image, name }) {
  function renderContentByType () {
    switch (type) {
      case 'team_photo':
        return <TeamPhotoWithLabels />
      case 'quote':
        if (!text) {
          return <div>No text content available for {name} block.</div>
        }
        return (
          <div className={`${styles.textGridPlug} ${styles[columns]} ${inColumns !== 'default' ? styles[inColumns] : ''}`}>
            <div className={styles.textQuote}>
              <div dangerouslySetInnerHTML={{ __html: text }} />
            </div>
          </div>
        )
      case 'image':
        if (!image) {
          return <div>No image content available for {name} block.</div>
        }
        return (
          (image &&
            <div className={`${styles.imgGridPlug} ${styles[columns]} ${inColumns !== 'default' ? styles[inColumns] : ''}`}>
              <Image imgObj={image} props={{ alt: name }} />
            </div>
          )
        )
      default:
        return (
          <div className={`${styles.textGridPlug} ${styles[columns]} ${inColumns !== 'default' ? styles[inColumns] : ''}`}>
            Please check your grid element with name {name}, the type is not defined.
          </div>
        )
    }
  }

  return (
    <>
      {renderContentByType()}
    </>
  )
}
