import React, { useRef, useEffect, useState } from 'react'
import Icon from './icons'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

import styles from './team-menu.module.css'
import { cn, debounce } from '../lib/helpers'

gsap.registerPlugin(ScrollToPlugin)

function TeamMenu ({ innerRef, nodes, current, pageTitle }) {
  const filterButtonRef = useRef(null)
  const menuRef = useRef(null)

  const groupContainerRef = useRef(null)
  const [initialTeamMembers, setInitialTeamMembers] = useState('')

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [activeMenuItem, setActiveMenuItem] = useState(current)

  const [isSticky, setIsSticky] = useState(false)

  useEffect(() => {
    const sentinel = document.querySelector('.sentinel')

    const observerOptions = {
      root: null,
      threshold: [1]
    }

    // eslint-disable-next-line no-undef
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.intersectionRatio < 1) {
          setIsSticky(true)
        } else {
          setIsSticky(false)
        }
      })
    }, observerOptions)

    observer.observe(sentinel)

    return () => {
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    const displayContainer = innerRef.displayContainerRef.current
    setInitialTeamMembers(displayContainer.innerHTML)

    checkMenuMobile()
    window.addEventListener('resize', checkMenuMobile)
  }, [innerRef.teamMembersWrapRef])

  function checkMenuMobile () {
    setIsMenuOpen(window.innerWidth > 768)
  }

  function filterTeam (e, requestedTeam) {
    // Set current filter
    const element = 'currentTarget' in e ? e.currentTarget : e
    const list = element.parentNode
    const currentItem = list.querySelector('.current')
    if (currentItem) {
      currentItem.classList.remove(styles.current)
      currentItem.classList.remove('current')
    }

    element.classList.add(styles.current)
    element.classList.add('current')
    setActiveMenuItem(requestedTeam)
    if (window.innerWidth < 768) toggleMenu()

    const teamMembersWrap = innerRef.teamMembersWrapRef.current
    const teamMembers = Array.from(teamMembersWrap.querySelectorAll('[data-teams]'));
    const displayContainer = innerRef.displayContainerRef.current
    gsap.to(window, { duration: 1, scrollTo: { y: '#profiles', offsetY: 230 }, ease: 'power4.inOut' })
    gsap.fromTo('[data-filtered-teams]', { opacity: 1 }, {
      duration: 0.3,
      opacity: 0,
      onComplete: () => {
        if (requestedTeam === 'all') {
          displayContainer.innerHTML = initialTeamMembers
        } else {
          const filteredMembers = teamMembers.filter(card => {
            const teams = card.dataset.teams
            return teams.indexOf(requestedTeam) > -1
          })

          filteredMembers.sort((a, b) => {
            const nameA = a.dataset.lastname.toLowerCase()
            const nameB = b.dataset.lastname.toLowerCase()
            return nameA.localeCompare(nameB)
          })

          displayContainer.innerHTML = ''
          filteredMembers.forEach(card => {
            const clone = card.cloneNode(true)
            displayContainer.appendChild(clone)
          })
        }
        gsap.fromTo('[data-filtered-teams]', { opacity: 0 }, { duration: 0.3, opacity: 1 })
      }
    })
  }

  const debouncedSearchTeam = debounce((query) => {
    const groupContainer = groupContainerRef.current
    const teamMembersWrap = innerRef.teamMembersWrapRef.current
    const displayContainer = innerRef.displayContainerRef.current

    if (query !== '') {
      groupContainer.classList.add(styles.hide)
      displayContainer.innerHTML = ''
      let count = 0

      const matchingMembers = nodes.filter(member => {
        const name = member.name.toLowerCase()
        const department = member.department.map(t => t.toLowerCase())

        const nameMatch = name.includes(query)

        const teamMatch = department.some(team => team.includes(query))

        return (nameMatch || teamMatch) && count < 8
      })

      matchingMembers.sort((a, b) => a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase()))

      matchingMembers.forEach(member => {
        const foundMember = teamMembersWrap.querySelector(`[data-member="${member.name}"]`)
        if (foundMember) {
          const clone = foundMember.cloneNode(true)
          displayContainer.appendChild(clone)
          count++
        }
      })
    } else {
      groupContainer.classList.remove(styles.hide)
      if (activeMenuItem === 'all') {
        displayContainer.innerHTML = initialTeamMembers
      }
    }
  }, 300)

  function searchTeam (event) {
    const query = event.target.value.toLowerCase()
    debouncedSearchTeam(query)
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <div className={cn(styles.teamMenu, isSticky && styles.sticky)} ref={menuRef}>
      <h1>{pageTitle}</h1>
      <div>
        <div ref={groupContainerRef} className={styles.teamGroups} data-group-container=''>
          <div ref={filterButtonRef} className={`${styles.filterButton} ${isMenuOpen ? styles.filtersOpened : ''}`} onClick={toggleMenu}>Filters</div>
          {isMenuOpen && (
            <div className={styles.grid}>
              <a
                onClick={(e) => filterTeam(e, 'all')} data-team='all'
                className={`${activeMenuItem === 'all' ? styles.current + ' current' : ''}`}
              >
                All
              </a>
              <a
                onClick={(e) => filterTeam(e, 'investors')} data-team='investors'
                className={`${activeMenuItem === 'investors' ? styles.current + ' current' : ''}`}
              >
                Investors
              </a>
              <a
                onClick={(e) => filterTeam(e, 'founder-experience')} data-team='founder-experience'
                className={`${activeMenuItem === 'founder-experience' ? styles.current + ' current' : ''}`}
              >
                Founder Experience
              </a>
              <a
                onClick={(e) => filterTeam(e, 'founders')} data-team='founders'
                className={`${activeMenuItem === 'founders' ? styles.current + ' current' : ''}`}
              >
                Founders + Emeritus
              </a>
              <a
                onClick={(e) => filterTeam(e, 'operations')} data-team='operations'
                className={`${activeMenuItem === 'operations' ? styles.current + ' current' : ''}`}
              >
                Operations
              </a>
              <div className={styles.closeFilters} onClick={toggleMenu} />
            </div>
          )}
        </div>
        <div className={styles.searchBox}>
          <div>
            <Icon symbol='search' />
            {/* eslint-disable-next-line react/jsx-no-bind */}
            <input type='text' placeholder='Search' onChange={searchTeam} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeamMenu
