import React from 'react'
import styles from '../people-grid.module.css'
import teamImg from '../../img/redpoint-team-image-1.png'

export default function TeamPhotoWithLabels () {
  return (
    <>
      <div className={`${styles.imgGridPlug} ${styles.teamLabels} ${styles.twoColumns}`}>
        <img src={teamImg} alt='Team photo' />
        <ul>
          <li><span>Alex<span>Bard</span></span></li>
          <li><span>Erica<span>Brescia</span></span></li>
          <li><span>Annie<span>Kadavy</span></span></li>
          <li><span>Satish<span>Dharmaraj</span></span></li>
        </ul>
      </div>
    </>
  )
}
