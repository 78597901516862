import React, { useEffect, useRef, useState } from 'react'
import Container from '../components/container'
import ProfileCard from './profile-card'
import TeamMenu from './team-menu'
import GridPlugin from './teamCardsSections'

import styles from './people-grid.module.css'

function PeopleGrid ({ nodes, body, gridPlugins, pageTitle }) {
  const teamMembersWrapRef = useRef(null)
  const displayContainerRef = useRef(null)
  const [countElements, setCountElements] = useState(0)

  const sortNodes = (nodes) => {
    const teamLocal = ['investors', 'founder-experience', 'operations', 'founders']

    return nodes.sort((a, b) => {
      const departmentA = a.team[0]
      const departmentB = b.team[0]
      const departmentComparison = teamLocal.indexOf(departmentA) - teamLocal.indexOf(departmentB)

      if (departmentComparison !== 0) {
        return departmentComparison
      }

      // const sortOrderComparison = b.sortOrder - a.sortOrder;
      // if (sortOrderComparison !== 0) {
      //   return sortOrderComparison;
      // }

      const nameA = a.lastName.toLowerCase()
      const nameB = b.lastName.toLowerCase()
      return nameA.localeCompare(nameB)
    })
  }

  const filteredNodes = sortNodes(
    nodes.filter(node => node.display !== false)
  )

  function setEqualHeight() {
    const personInfoElements = document.querySelectorAll('.profileCard .personInfo');
    let maxHeight = 0;

    personInfoElements.forEach((element) => {
      element.style.minHeight = 'auto';
    });

    personInfoElements.forEach((element) => {
      const height = element.offsetHeight;
      if (height > maxHeight) {
        maxHeight = height;
      }
    });

    personInfoElements.forEach((element) => {
      element.style.minHeight = `${maxHeight}px`;
    });
  }


  useEffect(() => {
    const updateCount = () => {
      const container = displayContainerRef.current
      if (container) {
        setCountElements(container.children.length)
      }
    }

    // eslint-disable-next-line no-undef
    const observer = new MutationObserver(updateCount)

    if (displayContainerRef.current) {
      observer.observe(displayContainerRef.current, {
        childList: true
      })
    }
    updateCount()
    return () => {
      if (displayContainerRef.current) {
        observer.disconnect()
      }
    }
  }, [])

  useEffect(() => {
    if (window.GlobalState.isBackNavigation) {
      const fromPersonSlug = localStorage.getItem('lastVisitedPerson')
      if (fromPersonSlug) {
        const targetCard = document.querySelector(`[data-slug="${fromPersonSlug}"]`)
        if (targetCard && countElements > 0) {
          targetCard.scrollIntoView({ block: 'center' })
          localStorage.removeItem('lastVisitedPerson')
        }
      }
    }
    setEqualHeight()
    window.addEventListener('resize', setEqualHeight);
    return () => {
      window.removeEventListener('resize', setEqualHeight);
    };
  }, [countElements])

  return (
    <div className={`${styles.root}`} id='next'>
      <div className='sentinel' />
      <TeamMenu
        innerRef={{ teamMembersWrapRef, displayContainerRef }} nodes={nodes} pageTitle={pageTitle}
        current='all'
      />
      <Container size='large'>
        <div
          ref={displayContainerRef} className={`${styles.grid}`} id='profiles' data-filtered-teams=''
          style={{ display: countElements === 0 ? 'none' : 'grid' }}
        >
          {filteredNodes.map((node, index) => (
            <React.Fragment key={node.id}>
              <div>
                <ProfileCard {...node} />
              </div>
              {gridPlugins.map((plugin) => (
                (index >= 0 && (index === plugin.position) && (
                  <React.Fragment key={plugin._id}>
                    <GridPlugin
                      columns={plugin.columns} inColumns={plugin.columns_m} type={plugin.type}
                      image={plugin.image} text={plugin.text} name={plugin.name}
                    />
                  </React.Fragment>
                )
                )))}
            </React.Fragment>
          ))}
        </div>
        <p className={styles.noResults} style={{ display: countElements === 0 ? 'block' : 'none' }}>No results found</p>

        <div ref={teamMembersWrapRef} className={`${styles.hidden}`} data-filter-teams=''>
          {nodes &&
                nodes.map(node => (
                  <div key={node.id}>
                    <ProfileCard {...node} />
                  </div>
                ))}
        </div>
      </Container>
    </div>
  )
}

export default PeopleGrid
